import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate  } from 'react-router-dom';
import './Admin.css';
import ConfirmationModal from '../components/ConfirmationModal';

// const API_BASE_URL = 'https://172.29.49.138:5000/api'; // Replace with your WSL2 IP address
const API_BASE_URL = 'https://schulzepaul.com/api';
//const API_BASE_URL = 'https://192.168.50.135:5000/api';

const Admin = () => {
    const [projects, setProjects] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        summary: '',
        description: '',
        technologies: '',
        github_url: '',
        demo_url: ''
    });
    const [images, setImages] = useState([]); // To store uploaded image files
    const [showProjectsOverlay, setShowProjectsOverlay] = useState(false);
    const [showFeedbackOverlay, setShowFeedbackOverlay] = useState(false);
    const [feedbackEntries, setFeedbackEntries] = useState([]);
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [feedbackToDelete, setFeedbackToDelete] = useState(null); // Store the feedback ID for the confirmation dialog.

    const [deleteProjectConfirmVisible, setDeleteProjectConfirmVisible] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null); 

    const navigate = useNavigate();

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/check_login`, { withCredentials: true });
                if (response.data.logged_in) {
                    setIsLoggedIn(true);
                } else {
                    navigate('/login');
                }
            } catch (error) {
                console.error('Error checking login status', error);
            }
        };

        checkLoginStatus();
    }, [navigate]);

    useEffect(() => {
        console.log('Updated isLoggedIn state:', isLoggedIn); // Log the state after it has been updated
        if (isLoggedIn) {
            fetchProjects();
            fetchFeedback();
        }
    }, [isLoggedIn]);

    const handleLogin = async (e) => {
        e.preventDefault();
        const loginData = {
            username: e.target.username.value,
            password: e.target.password.value
        };
    
        try {
            await axios.post(`${API_BASE_URL}/login`, loginData, { withCredentials: true });
            
            // After successful login, update the login status
            setIsLoggedIn(true); // Update the state (provided by AuthContext)
    
            // Navigate to the admin page after successful login
            // navigate('/admin');
            navigate('/otp-verification');
        } catch (error) {
            setError('Invalid username or password');
        }
    };

    const handleLogout = async () => {
        try {
            await axios.post(`${API_BASE_URL}/logout`, {}, { withCredentials: true });
            setIsLoggedIn(false);
            navigate('/');
        } catch (error) {
            setError('Failed to logout');
        }
    };

    const fetchProjects = () => {
        axios.get(`${API_BASE_URL}/projects`)
            .then(response => {
                console.log('Projects fetched successfully:', response.data); // Log fetched projects
                setProjects(response.data.projects);
            })
            .catch(error => {
                console.error('There was an error fetching the projects!', error); // Log error
                setError('Failed to fetch projects. Please try again later.');
            });
    };

    const fetchFeedback = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/feedback`, { withCredentials: true });
            setFeedbackEntries(response.data.feedback);
        } catch (error) {
            setError('Failed to fetch feedback');
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    
    const handleFileChange = (e) => {
        setImages([...e.target.files]); // Store selected files
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSubmit = new FormData();
    
        // Append the text fields
        Object.keys(formData).forEach(key => {
            formDataToSubmit.append(key, formData[key]);
        });
    
        // Append the image files
        images.forEach(image => {
            formDataToSubmit.append('images', image);
        });
    
        try {
            // Use this to log every key/value in FormData
            for (let pair of formDataToSubmit.entries()) {
                console.log(pair[0]+ ': ' + pair[1]);
            }
            
            const response = await axios.post(`${API_BASE_URL}/projects`, formDataToSubmit, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });
    
            console.log('Project added successfully:', response.data); // Log success response
            fetchProjects();
            // Reset the form fields
            setFormData({
                title: '',
                summary: '',
                description: '',
                technologies: '',
                github_url: '',
                demo_url: ''
            });
            setImages([]); // Clear uploaded images
    
        } catch (error) {
            console.error('There was an error creating the project!', error); // Log error
            setError('Failed to add project. Please try again later.');
        }
    };

    const deleteProject = (id) => {
        axios.delete(`${API_BASE_URL}/projects/${id}`, {
            withCredentials: true
        })
        .then(response => {
            console.log('Project deleted successfully:', response.data); // Log success response
            fetchProjects();
        })
        .catch(error => {
            console.error('There was an error deleting the project!', error); // Log error
            setError('Failed to delete project. Please try again later.');
        });
    };

    // When clicking "Delete", trigger the confirmation modal with the targeted feedback
    const handleDeleteClick = (id) => {
        setFeedbackToDelete(id);
        setDeleteConfirmVisible(true);
    };

    // Confirm the deletion and proceed to delete the feedback via the API
    const confirmDeleteFeedback = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/feedback/${feedbackToDelete}`, { withCredentials: true });
            fetchFeedback();  // Refresh the feedback list
            setDeleteConfirmVisible(false);  // Close the confirmation modal
        } catch (error) {
            setError('Failed to delete feedback. Please try again.');
            setDeleteConfirmVisible(false);
        }
    };

    // Abort deletion action and close the confirmation modal
    const abortDeleteFeedback = () => {
        setDeleteConfirmVisible(false);
    };

    // Handle delete click for projects
    const handleProjectDeleteClick = (id) => {
        setProjectToDelete(id);
        setDeleteProjectConfirmVisible(true);  // Show project delete confirmation modal
    };

    // Confirm project deletion
    const confirmDeleteProject = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/projects/${projectToDelete}`, { withCredentials: true });
            fetchProjects();  // Refresh project list after deletion
            setDeleteProjectConfirmVisible(false);
        } catch (error) {
            setError('Failed to delete project.');
            setDeleteProjectConfirmVisible(false);
        }
    };

    const abortDeleteProject = () => {
        setDeleteProjectConfirmVisible(false);
    };

    return (
        <div className="admin-container">
            {isLoggedIn ? (
                <>
                    <h1 className="admin-title">Admin Panel</h1>
                    {error && <div className="error-message">{error}</div>}
                    <form className="admin-form" onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Title:</label>
                            <input type="text" name="title" value={formData.title} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Summary:</label>
                            <input type="text" name="summary" value={formData.summary} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Description:</label>
                            <textarea name="description" value={formData.description} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>Technologies:</label>
                            <input type="text" name="technologies" value={formData.technologies} onChange={handleChange} required />
                        </div>
                        <div className="form-group">
                            <label>GitHub URL:</label>
                            <input type="text" name="github_url" value={formData.github_url} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Demo URL:</label>
                            <input type="text" name="demo_url" value={formData.demo_url} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <label>Images:</label>
                            <input type="file" name="images" multiple onChange={handleFileChange} accept="image/*" />
                        </div>
                        <button type="submit" className="submit-button">Add Project</button>
                    </form>
                    <div className="button-group">
                        <button className="overlay-button" onClick={() => setShowProjectsOverlay(true)}>View Existing Projects</button>
                        <button className="overlay-button" onClick={() => setShowFeedbackOverlay(true)}>View Feedback Submissions</button>
                        <button className="logout-button" onClick={handleLogout}>Logout</button>
                    </div>
                    {showProjectsOverlay && (
                        <div className="overlay">
                            <div className="overlay-content">
                                <button className="close-button" onClick={() => setShowProjectsOverlay(false)}>Close</button>
                                <h2 className="section-title">Existing Projects</h2>
                                <div className="project-list">
                                    {projects.map((project) => (
                                        <div className="project-item" key={project.id}>
                                            <h3>{project.title}</h3>
                                            <p>{project.summary}</p>
                                            <div className="project-actions">
                                                <button onClick={() => handleProjectDeleteClick(project.id)} className="delete-button">
                                                    Delete
                                                </button>
                                                <Link to={`/admin/edit/${project.id}`} className="edit-link">Edit</Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {showFeedbackOverlay && (
                        <div className="overlay">
                            {/* Feedback List Overlay */}
                            <div className="overlay-content">
                                <button className="close-button" onClick={() => setShowFeedbackOverlay(false)}>Close</button>
                                <h2 className="section-title">Feedback Submissions</h2>
                                
                                <div className="feedback-list">
                                    {feedbackEntries.map((feedback) => (
                                        <div className="feedback-item" key={feedback.id}>
                                            <div className="feedback-header">
                                                <h3>{feedback.name}</h3>
                                                <p>{feedback.email}</p>
                                            </div>
                                            <p>{feedback.message}</p>
                                            <div className="feedback-actions">
                                                <button
                                                    onClick={() => handleDeleteClick(feedback.id)}
                                                    className="delete-button"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                    {/* Using the ConfirmationModal component */}
                    <ConfirmationModal 
                        show={deleteConfirmVisible}         // Whether to show modal
                        message="Are you sure you want to delete this feedback?"  // Message displayed in modal
                        onConfirm={confirmDeleteFeedback}  // Confirming delete action
                        onCancel={abortDeleteFeedback}     // Aborting delete action
                    />
                    {/* Confirmation Modal for Deleting Projects */}
                    <ConfirmationModal
                        show={deleteProjectConfirmVisible}
                        message="Are you sure you want to delete this project?"
                        onConfirm={confirmDeleteProject}
                        onCancel={abortDeleteProject}
                    />
                </>
            ) : (
                <form onSubmit={handleLogin}>
                    <input type="text" name="username" placeholder="Username" required />
                    <input type="password" name="password" placeholder="Password" required />
                    <button type="submit">Login</button>
                </form>
            )}
        </div>
    );
};

export default Admin;
