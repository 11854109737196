// ConfirmationModal.js
import React from 'react';
import ReactDOM from 'react-dom';
import './ConfirmationModal.css'; // Add any specific styling you need here

// Modal component using a React Portal
const ConfirmationModal = ({ message, show, onConfirm, onCancel }) => {
    if (!show) return null; // Don't render anything if `show` is false

    // Create a portal for the modal
    return ReactDOM.createPortal(
        <div className="confirmation-backdrop">
            <div className="confirmation-modal">
                <p>{message}</p>
                <div className="confirmation-actions">
                    <button onClick={onConfirm} className="confirm-button">
                        Yes, delete
                    </button>
                    <button onClick={onCancel} className="cancel-button">
                        Abort
                    </button>
                </div>
            </div>
        </div>,
        document.body // This renders the modal at the root level of the DOM
    );
};

export default ConfirmationModal;
